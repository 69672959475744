import { number, object, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'
import removeHtmlTagsFromString from 'utils/removeHtmlTagsFromString'

import { useSlides } from '../../../../hooks/slides'

import { Image, ImageContainer, ProgressIndicator, Wrapper } from './styles'

const propTypes = {
  index: number,
  indicatorImage: object,
  interval: number,
  slideId: string,
}

const SlideIndicator = ({
  index,
  indicatorImage,
  interval,
  slideId,
  ...others
}) => {
  const {
    activeSlide,
    setActiveSlide,
    stopAnimation,
    stopSlideSequence,
  } = useSlides()

  const { triggerEvent } = useTriggerEvents()

  const dispatchEvent = ({ title }) => {
    triggerEvent({
      label: `${index + 1} | ${removeHtmlTagsFromString(title)}`,
      pageInteraction: true,
    })
  }

  const handleClick = () => {
    setActiveSlide(slideId, dispatchEvent)
    stopSlideSequence()
  }

  return (
    <Wrapper onClick={handleClick} {...others}>
      <ImageContainer>
        <Image
          {...indicatorImage?.fluid}
          className={activeSlide.id === slideId ? 'active' : ''}
        />
      </ImageContainer>
      <ProgressIndicator
        preserveAspectRatio="xMinYMin meet"
        interval={interval}
        className={
          activeSlide.id === slideId && !stopAnimation ? 'animated' : ''
        }
      >
        <circle fill="transparent" r="47%" cx="50%" cy="50%" />
      </ProgressIndicator>
    </Wrapper>
  )
}

SlideIndicator.propTypes = propTypes
export default SlideIndicator
