import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'
import { Grid } from 'components/Grid'

export const WrapperContainer = styled.section`
  width: 100%;
  height: 100%;
  background-color: ${selectTheme('colors.neutral.10')};

  ${media.up('5xl')} {
    height: 768px;
  }
`

export const Container = styled(CreditasContainer)`
  height: 100%;
  position: relative;
`

export const Content = styled(Grid)`
  height: 100%;
  grid-template-rows: 'max-content';
  grid-template-areas:
    '${'content '.repeat(4)}'
    '${'image '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'content '.repeat(8)}'
      '${'image '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-rows: 100%;
    grid-template-areas: '${'image '.repeat(7)} ${'content '.repeat(5)}';
  }
`
