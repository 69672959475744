import { number, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { useSlides } from 'sections/GenericSlides/hooks/slides'

import { ButtonCta as ButtonCtaComponent } from 'components/ButtonCta'
import { ArrowRightIcon } from 'components/Icons'

const propTypes = {
  actionType: string,
  index: number,
  label: string,
  variant: string,
}

const ButtonCta = ({ actionType, index, label, variant, ...others }) => {
  const {
    activeSlide: { index: slideIndex },
  } = useSlides()

  const { triggerEvent } = useTriggerEvents()

  const handleClick = () => {
    const isPageInteraction = actionType !== 'link'
    triggerEvent({
      label: `${slideIndex + 1} | ${index + 1} | ${label}`,
      pageInteraction: isPageInteraction,
    })
  }

  return (
    <ButtonCtaComponent
      {...others}
      actionType={actionType}
      label={label}
      variant={variant}
      onClick={handleClick}
      analyticEvent={false}
    >
      <ArrowRightIcon />
    </ButtonCtaComponent>
  )
}

ButtonCta.propTypes = propTypes

export { ButtonCta }
