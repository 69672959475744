import {
  array,
  arrayOf,
  node,
  number,
  object,
  oneOf,
  shape,
  string,
} from 'prop-types'

import { SlideProvider } from '../../hooks/slides'

import { Container, Content, WrapperContainer } from './styles'

const propTypes = {
  children: node,
  interval: number,
  slides: arrayOf(
    shape({
      title: string,
      titleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
      preTitle: string,
      preTitleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
      description: string,
      mainImageDesktop: object,
      mainImageMobile: object,
      indicatorImage: object,
      buttonCtas: array,
    }),
  ),
}

const defaultProps = {
  interval: 10,
}

const Wrapper = ({ children, interval, slides, ...others }) => {
  const currentInterval = interval ?? defaultProps.interval

  return (
    <SlideProvider slides={slides} interval={currentInterval}>
      <WrapperContainer {...others}>
        <Container>
          <Content>{children}</Content>
        </Container>
      </WrapperContainer>
    </SlideProvider>
  )
}

Wrapper.propTypes = propTypes
export default Wrapper
