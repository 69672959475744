import { node } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: node,
}

const Description = ({ children, ...others }) => {
  if (!children) return null
  return (
    <Typography
      color="neutral.90"
      variant="bodyMdRegular"
      data-testid="description"
      {...others}
    >
      {children}
    </Typography>
  )
}

Description.propTypes = propTypes

export default Description
