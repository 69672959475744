import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

const INDICATOR_CIRCLE_RADIUS = { sm: 28, md: 38 }

export const Wrapper = styled.div(
  () => css`
    width: ${INDICATOR_CIRCLE_RADIUS.sm * 2}px;
    height: ${INDICATOR_CIRCLE_RADIUS.sm * 2}px;
    position: relative;

    ${media.up('5xl')} {
      width: ${INDICATOR_CIRCLE_RADIUS.md * 2}px;
      height: ${INDICATOR_CIRCLE_RADIUS.md * 2}px;
    }
  `,
)

export const ProgressIndicator = styled.svg(
  ({ interval, theme }) => css`
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    stroke-width: 1px;

    circle {
      stroke: ${selectTheme('colors.primary.60')({ theme })};
      stroke-dasharray: ${INDICATOR_CIRCLE_RADIUS.sm * 2 * Math.PI};
      stroke-dashoffset: ${INDICATOR_CIRCLE_RADIUS.sm * 2 * Math.PI};
    }

    ${media.up('5xl')} {
      circle {
        stroke-dasharray: ${INDICATOR_CIRCLE_RADIUS.md * 2 * Math.PI};
        stroke-dashoffset: ${INDICATOR_CIRCLE_RADIUS.md * 2 * Math.PI};
      }
    }

    @keyframes progress {
      to {
        stroke-dashoffset: 0;
      }
    }

    &.animated {
      circle {
        animation: progress ${interval}s linear;
      }
    }
  `,
)

export const ImageContainer = styled.div`
  height: 48px;
  width: 48px;

  border-radius: 50%;
  background: ${selectTheme('colors.neutral.15')};

  position: absolute;
  overflow: hidden;

  cursor: pointer;
  z-index: 2;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${media.up('5xl')} {
    width: 64px;
    height: 64px;
  }
`

export const Image = styled(LazyImage)`
  width: 100%;
  height: 100%;

  mix-blend-mode: multiply;

  &.active,
  &:hover {
    mix-blend-mode: normal;
  }
`
