/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  array,
  arrayOf,
  node,
  number,
  object,
  oneOf,
  shape,
  string,
} from 'prop-types'

const SlideContext = createContext({
  activeSlide: {},
  activeSlideImage: {},
  activeContentAnimationClass: '',
  activeIndicatorAnimationClass: '',
  goToNextSlide: () => {},
  goToPreviousSlide: () => {},
  setActiveSlide: () => {},
  stopAnimation: false,
  stopSlideSequence: () => {},
})

const propTypes = {
  children: node,
  interval: number,
  slides: arrayOf(
    shape({
      buttonCtas: array,
      description: string,
      indicatorImage: object,
      mainImageDesktop: object,
      mainImageMobile: object,
      preTitle: string,
      preTitleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
      title: string,
      titleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
      index: number,
    }),
  ),
}

const defaultProps = {
  slides: [],
  interval: 10,
}

const SlideProvider = ({
  children,
  interval: intervalInSecs,
  slides: arraySlides,
}) => {
  const convertedInterval = intervalInSecs ?? defaultProps.interval
  const interval = convertedInterval * 1000
  let removeAnimatedClassTimer = null
  let slideInterval = null
  const [stopAnimation, setStopAnimation] = useState(false)
  const slides = arraySlides ? arraySlides : [{}]

  const [data, setData] = useState(() => {
    const firstSlide = slides[0]

    return {
      activeSlide: { ...(firstSlide || {}), index: 0 },
      activeContentAnimationClass: 'animated',
    }
  })

  const activeSlideImage = useMemo(() => {
    const { activeSlide } = data
    return {
      desktop: activeSlide.mainImageDesktop?.fluid,
      mobile: activeSlide.mainImageMobile?.fluid,
      alt:
        activeSlide.mainImageDesktop?.description ||
        activeSlide.mainImageMobile?.description,
    }
  }, [data])

  const setActiveSlide = useCallback(
    (id, onActive) => {
      const targetSlideIndex = slides?.findIndex(slide => slide.id === id)
      if (targetSlideIndex < 0) return

      clearSlideInterval()
      clearRemoveAnimationClassTimeout()

      const activeSlide = {
        index: targetSlideIndex,
        ...slides[targetSlideIndex],
      }

      setData(prev => ({
        ...prev,
        activeSlide,
      }))

      onActive?.(activeSlide)
    },
    [slides],
  )

  const goToNextSlide = useCallback(() => {
    const { activeSlide } = data
    const currentSlideIndex = slides?.findIndex(
      slide => slide.id === activeSlide.id,
    )

    if (currentSlideIndex < 0) return
    const isAtFinalSlide = currentSlideIndex === slides?.length - 1
    const nextStepId = isAtFinalSlide
      ? slides[0].id
      : slides[currentSlideIndex + 1].id

    setActiveSlide(nextStepId)
  }, [data, slides, setActiveSlide])

  const goToPreviousSlide = useCallback(() => {
    const { activeSlide } = data

    const currentSlideIndex = slides?.findIndex(
      slide => slide.id === activeSlide.id,
    )

    if (currentSlideIndex === 0) return
    const previousSlideId = slides[currentSlideIndex - 1].id
    setActiveSlide(previousSlideId)
  }, [data, slides, setActiveSlide])

  const initContentAnimation = useCallback(() => {
    if (slides?.length > 1) {
      setData(prev => ({ ...prev, activeContentAnimationClass: 'animated' }))
    }

    removeAnimatedClassTimer = setTimeout(
      () => setData(prev => ({ ...prev, activeContentAnimationClass: '' })),
      800,
    )
  }, [setData])

  function initSlideSequence() {
    if (!slideInterval) {
      slideInterval = setInterval(() => {
        goToNextSlide()
        initContentAnimation()
      }, interval)
    }
  }

  function stopSlideSequence() {
    setStopAnimation(true)
    return () => {
      clearSlideInterval()
    }
  }

  useEffect(() => {
    initContentAnimation()
    return () => clearRemoveAnimationClassTimeout()
  }, [initContentAnimation])

  useEffect(() => {
    if (slides?.length <= 1) return () => {}
    if (!stopAnimation) {
      initSlideSequence()
    }
    return () => {
      clearSlideInterval()
      clearRemoveAnimationClassTimeout()
    }
  }, [data.activeSlide])

  function clearSlideInterval() {
    clearInterval(slideInterval)
    slideInterval = null
  }

  function clearRemoveAnimationClassTimeout() {
    clearTimeout(removeAnimatedClassTimer)
    removeAnimatedClassTimer = null
  }

  return (
    <SlideContext.Provider
      value={{
        ...data,
        activeSlideImage,
        stopAnimation,
        stopSlideSequence,
        goToNextSlide,
        goToPreviousSlide,
        setActiveSlide,
      }}
    >
      {children}
    </SlideContext.Provider>
  )
}

SlideProvider.propTypes = propTypes
SlideProvider.defaultProps = defaultProps

function useSlides() {
  const context = useContext(SlideContext)

  return context
}

export { useSlides, SlideProvider }
