import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

export const Container = styled.div`
  grid-area: image;
  position: relative;
  width: 100%;
  max-width: 441px;
  margin-top: 20px;

  ${media.up('5xl')} {
    margin-top: 0;
    max-height: 100%;
    max-width: 100%;
  }
`

export const Image = styled(LazyImage)`
  height: 100%;
  width: 100%;

  @keyframes fadeInOutFromLeft {
    from {
      transform: translateX(-16px);
      opacity: 0;
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &.animated {
    animation: fadeInOutFromLeft 800ms ease-in-out forwards;
  }
`
