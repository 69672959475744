import {
  array,
  arrayOf,
  number,
  object,
  oneOf,
  shape,
  string,
} from 'prop-types'

import SlideIndicator from './components/SlideIndicator'

import { Container } from './styles'

const propTypes = {
  interval: number,
  slides: arrayOf(
    shape({
      title: string,
      titleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
      preTitle: string,
      preTitleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
      description: string,
      mainImageDesktop: object,
      mainImageMobile: object,
      indicatorImage: object,
      buttonCtas: array,
    }),
  ),
}

const defaultProps = {
  interval: 10,
}

const SlideIndicators = ({ interval, slides }) => {
  if (!slides?.length || slides.length <= 1) return null
  return (
    <Container>
      {slides.map(({ id: slideId, indicatorImage }, index) => (
        <SlideIndicator
          key={`generic-slide-${slideId}-indicator-${index}`}
          slideId={slideId}
          data-testid={`generic-slide-${slideId}-indicator-${index}`}
          interval={interval}
          index={index}
          indicatorImage={indicatorImage}
        />
      ))}
    </Container>
  )
}

SlideIndicators.propTypes = propTypes
SlideIndicators.defaultProps = defaultProps
export default SlideIndicators
