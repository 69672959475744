import { useSlides } from '../../hooks/slides'

import { ButtonCta } from './components/ButtonCta'
import Description from './components/Description'
import PreTitle from './components/PreTitle'
import Title from './components/Title'

import { Actions, Info, Wrapper } from './styles'

const SlideInfo = () => {
  const {
    activeContentAnimationClass,
    activeSlide: {
      buttonCtas,
      description,
      preTitle,
      preTitleHeadingTag,
      title,
      titleHeadingTag,
    },
  } = useSlides()

  return (
    <Wrapper className={activeContentAnimationClass}>
      <Info>
        <PreTitle as={preTitleHeadingTag || 'h2'}>{preTitle}</PreTitle>
        <Title as={titleHeadingTag || 'h1'}>{title}</Title>
        <Description>{description}</Description>
      </Info>
      <Actions>
        {buttonCtas?.map((buttonCta, index) => (
          <ButtonCta key={buttonCta.id} index={index} {...buttonCta} />
        ))}
      </Actions>
    </Wrapper>
  )
}

export default SlideInfo
