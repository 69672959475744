import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 64px;

  @keyframes fadeInOutFromRight {
    from {
      transform: translateX(16px);
      opacity: 0;
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &.animated {
    animation: fadeInOutFromRight 800ms ease-in-out forwards;
  }

  ${media.up('5xl')} {
    padding-top: 120px;
  }
`

export const Info = styled.div`
  width: 100%;
  margin-bottom: 48px;
`

export const Actions = styled.div`
  display: flex;

  a {
    text-decoration: none;
    flex-basis: 50%;
    font-weight: 600;

    & + a {
      margin-left: 16px;
    }

    svg {
      margin-right: 8px;
    }
  }

  ${media.up('5xl')} {
    a {
      flex-basis: auto;
      max-width: 184px;

      & + a {
        margin-left: 24px;
      }
    }
  }
`
