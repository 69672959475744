import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

export const Container = styled.div`
  display: flex;
  position: absolute;
  bottom: 32px;

  ${media.up('5xl')} {
    position: relative;
    bottom: 0;
    margin-bottom: 64px;
  }

  > div {
    & + div {
      margin-left: 8px;
    }
  }
`
