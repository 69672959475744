import {
  array,
  arrayOf,
  number,
  object,
  oneOf,
  shape,
  string,
} from 'prop-types'

import SlideImage from './components/SlideImage'
import SlideIndicators from './components/SlideIndicators'
import SlideInfo from './components/SlideInfo'
import Wrapper from './components/Wrapper'

import { ContentWrapper } from './styles'

const propTypes = {
  interval: number,
  slides: arrayOf(
    shape({
      title: string,
      titleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
      preTitle: string,
      preTitleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
      description: string,
      mainImageDesktop: object,
      mainImageMobile: object,
      indicatorImage: object,
      buttonCtas: array,
    }),
  ),
}

const defaultProps = {
  interval: 10,
}

export const GenericSlides = ({ interval, slides, ...others }) => {
  const currentInterval = interval ?? defaultProps.interval

  return (
    <Wrapper slides={slides} interval={currentInterval} {...others}>
      <SlideImage />
      <ContentWrapper>
        <SlideInfo />
        <SlideIndicators interval={currentInterval} slides={slides} />
      </ContentWrapper>
    </Wrapper>
  )
}

GenericSlides.propTypes = propTypes
export default GenericSlides
