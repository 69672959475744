import { useSlides } from '../../hooks/slides'

import { Container, Image } from './styles'

const propTypes = {}

const artDirectionImages = [
  {
    sizes: '(max-width: 900px) 100vw, 900px',
    media: '(min-width: 1024px)',
  },
  {
    sizes: '(max-width: 441px) 100vw, 441px',
  },
]

const getArtDirectoryForImage = (desktop, mobile) => {
  return [
    { ...artDirectionImages[0], ...desktop },
    { ...artDirectionImages[1], ...mobile },
  ]
}
const SlideImage = ({ ...others }) => {
  const { activeContentAnimationClass, activeSlideImage } = useSlides()

  return (
    <Container {...others}>
      <Image
        data-testid="slide-main-image"
        className={activeContentAnimationClass}
        alt={activeSlideImage.alt}
        artDirection={getArtDirectoryForImage(
          activeSlideImage.desktop,
          activeSlideImage.mobile,
        )}
      />
    </Container>
  )
}

SlideImage.propTypes = propTypes
export default SlideImage
